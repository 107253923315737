import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from '../ContentSelection';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import PrimaryButton from '../../shared/PrimaryButton';
import FilesStore from '../FilesStore';
import Style from './styles/ProgressBar.module.scss';
import UploadStore from './UploadStore';
import UploadResultsModal from './UploadResultsModal';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { useToast } from '@aurecon-creative-technologies/styleguide';

const ProgressBar: FC = () => {
  const completed = UploadStore.uploadCompletePercentage;
  const uploadFailed = UploadStore.showUploadFailed;
  const { addToast } = useToast();

  const closeWindowButtonClick = async () => {
    await UploadStore.openFileUploadWithTemplate();
    if (UploadStore.openFileError) {
      addToast({
        type: 'success',
        message: UploadStore.openFileError,
        timeout: 5000,
      });
    }

    UploadStore.clear();
    if (FilesStore.fileContainerStateId === FileContainerState.Published)
      FilesStore.setSelectedSection(ContentSelection.Published);
    else if (FilesStore.fileContainerStateId === FileContainerState.Shared)
      FilesStore.setSelectedSection(ContentSelection.Shared);
    else FilesStore.setSelectedSection(ContentSelection.WIP);
  };

  return (
    <>
      {completed < 100 && !uploadFailed && !UploadStore.openResultsModal && (
        <CentreOnPage>
          <Overlay className={Style.modalOverlay} />
          <div className={Style.modal}>
            <h1>
              Files currently uploading.
              <br />
              Don&apos;t close the browser.
            </h1>
            <p>{`${completed}%`}</p>
            <div className={Style.container}>
              <div className={Style.filler} style={{ width: `${completed}%` }}>
                <span className={Style.label}> </span>
              </div>
            </div>
            <p>To continue using Tucana, open a new browser window</p>
            <PrimaryButton onClick={() => window.open('/', '_blank')}>Open New Browser</PrimaryButton>
          </div>
        </CentreOnPage>
      )}

      <UploadResultsModal
        onConfirm={async () => {
          UploadStore.setOpenResultsModal(false);
          await closeWindowButtonClick();
        }}
      />
    </>
  );
};

export default observer(ProgressBar);
