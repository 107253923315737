import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskCard.module.scss';
import { classNames } from '../../utils/miscUtils';
import { ITask } from '../../api/authenticated/tasks/getTasks';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import UserCircle from '../shared/UserCircle';
import { formatDate } from '../../utils/dateUtils';
import PrimaryButton from '../shared/PrimaryButton';
import UserCircleCollection from '../shared/UserCircleCollection';
import TaskStatusIndicator from '../shared/TaskStatusIndicator';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';

export interface ITaskCardProps {
  task: ITask;
}

const TaskCard: FC<ITaskCardProps> = ({ task }) => {
  const navigate = useNavigate();

  const handleViewTaskClick = () => {
    navigate(`${Pages.Tasks.Route}/${task.id}`);
  };

  return (
    <li className={Style.taskCard}>
      <PrimaryButton className={Style.viewTaskButton} onClick={handleViewTaskClick}>
        View Task
      </PrimaryButton>
      <ul className={Style.list}>
        <li className={classNames(Style.label, Style.marginBottom16px)}>#{task.id}</li>
        <li className={Style.listItemInline}>
          <span className={Style.label}>Status</span>
          <TaskStatusIndicator taskStatusId={task.taskStatusId} displayTaskStatus={task.displayTaskStatus} />
        </li>
        <li className={Style.listItemInline}>
          <span className={Style.label}>Workflow</span>
          <span>{TaskTypeText[task.taskTypeId]}</span>
        </li>
        <li className={Style.listItemInline}>
          <span className={Style.label}>Suitability</span>
          <span>{`${task.suitabilityCode} ${task.suitabilityTitle}`}</span>
        </li>
        <li className={classNames(Style.listItemInline, Style.marginBottom16px)}>
          <span className={Style.label}>Files</span>
          <span>{task.taskFileContainers.flatMap((t) => t.containerFiles).length}</span>
        </li>
        <li className={classNames(Style.marginBottom16px, Style.listItemDivider)}>
          <div className={classNames(Style.marginBottom16px, Style.message)}>{task.message}</div>
        </li>
        <li className={Style.listItemInline}>
          <span className={Style.label}>Start Date</span>
          <span>{formatDate(task.createdDate)}</span>
        </li>
        <li className={classNames(Style.listItemInline, Style.marginBottom16px)}>
          <span className={Style.label}>Last Updated</span>
          <span>{formatDate(task.lastUpdatedDate)}</span>
        </li>
        {task.dueDate && (
          <li className={classNames(Style.listItemInline, Style.marginBottom16px)}>
            <span className={Style.label}>Due Date</span>
            <span>{formatDate(task.dueDate)}</span>
          </li>
        )}
        <li className={classNames(Style.marginBottom16px, Style.listItemDivider)}></li>
        <li className={Style.listItemInline}>
          <span className={Style.label}>Assigned by</span>
          <span>
            <UserCircle
              initials={task.createdByUserInitials}
              name={task.createdByUserName}
              email={task.createdByUserEmail}
            />
          </span>
        </li>
        <li className={Style.listItemInline}>
          <span className={Style.label}>Assigned to</span>
          <UserCircleCollection
            users={task.taskUsers.map((u) => ({
              id: u.userId,
              name: u.name,
              email: u.email,
              initials: u.initials,
            }))}
          />
        </li>
      </ul>
    </li>
  );
};

export default observer(TaskCard);
